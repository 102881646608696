import React, { useEffect } from 'react';
import { Title } from './CookiePolicyStyles';

export const CookiePolicyContainer = () => {
  let isBrowser = () => typeof window !== 'undefined';

  useEffect(() => {
    // Inject Cookiebot declaration
    if (isBrowser()) {
      const cookiebotCookieDeclaratioScript = document.createElement('script');
      cookiebotCookieDeclaratioScript.src = 'https://consent.cookiebot.com/9f2f872a-6964-4d12-845d-48c56ec04dc1/cd.js';
      cookiebotCookieDeclaratioScript.type = 'text/javascript';
      cookiebotCookieDeclaratioScript.async = true;

      const cookiebotWrapperEl = document.getElementById('COOKIEBOT_DECLARATION_WRAPPER_ID');
      if (cookiebotWrapperEl) {
        cookiebotWrapperEl.appendChild(cookiebotCookieDeclaratioScript);
      }
    }
  }, []);

  return (
    <section>
      <Title>Cookie policy</Title>

      <div className={'container'}>
        <br />
        <br />
        <div id={'COOKIEBOT_DECLARATION_WRAPPER_ID'}></div>
      </div>
    </section>
  );
};
